import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DxDataGridComponent } from 'devextreme-angular';
import * as _ from 'lodash';
import * as moment from 'moment';
import { AppInformationService, AuthService, CdrUnitOfWork, InvoiceService, ReportService, UtilityService } from 'src/app/core/core.module';
import { Customer, Invoice, InvoiceStatus } from 'src/app/core/model/entity-model';
import { EmailDialogComponent } from 'src/app/shared/email-dialog/email-dialog.component';
import { DialogService, alert, confirm } from 'src/app/shared/shared.module';

@Component({
    selector: 'app-invoice-overview',
    templateUrl: './invoice-overview.component.html',
    styleUrls: ['./invoice-overview.component.scss']
})
export class InvoiceOverviewComponent implements OnInit {
    invoices: Invoice[];
    invoiceStatuses: InvoiceStatus[];
    standardExcludeStatusIds: any[];
    excludeStatusIds: any[];
    rentals: any[];
    residentialJobs: any[];
    showAll = false;
    isInvoiceAdmin = false;

    @Input() showAsComponent: boolean = false;
    @Input() customerId: number | null = null;
    @ViewChild('residentialJobsGrid') residentialJobsGrid: DxDataGridComponent;
    @ViewChild('commercialJobsGrid') commercialJobsGrid: DxDataGridComponent;
    @ViewChild('overviewGrid') overviewGrid: DxDataGridComponent;

    constructor(
        private uow: CdrUnitOfWork,
        private router: Router,
        private appInfo: AppInformationService,
        public auth: AuthService,
        private reportService: ReportService,
        private dialogService: DialogService,
        private utilityService: UtilityService,
        private invoiceService: InvoiceService
    ) {}

    async ngOnInit() {
        this.invoiceStatuses = this.uow.lookups.invoiceStatuses as InvoiceStatus[];
        this.standardExcludeStatusIds = [InvoiceStatus.Statuses.Paid, InvoiceStatus.Statuses.Voided, InvoiceStatus.Statuses.Complete];
        this.excludeStatusIds = this.standardExcludeStatusIds;
        this.isInvoiceAdmin = this.auth.hasRole('Invoice:Admin');

        await this.getData();
    }

    async toggleShowAll() {
        this.showAll = !this.showAll;
        if (this.showAll) this.excludeStatusIds = [];
        else this.excludeStatusIds = this.standardExcludeStatusIds;
        await this.getData();
    }

    async getData() {
        this.appInfo.pleaseWaitShow();
        this.residentialJobs = await this.uow.jobs.getResidentialJobsForInvoicing();
        this.rentals = await this.uow.rentals.getCommercialRentals();
        this.invoices = await this.uow.getActiveInvoices({
            customerId: this.customerId,
            expand: ['customer'],
            excludeStatusIds: this.excludeStatusIds,
            onlyUnPaid: true
        });
        (this.invoices as any).forEach(invoice => {
            if (invoice.customer.billingType === 'Credit-Debit') invoice.showCreditCard = true;
            else if (invoice.customer.preferredCommunication === 'Email') invoice.showEmail = true;
            else if (invoice.customer.preferredCommunication === 'Phone') invoice.showHome = true;
        });
        this.appInfo.pleaseWaitHide();
    }

    onToolbarPreparing(e) {
        e.toolbarOptions.items.unshift(
            {
                location: 'before',
                template: 'toolbarButtons'
            },
            {
                template: 'toolbarText',
                location: 'after'
            },
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'revert',
                    elementAttr: { title: 'Reset Filters' },
                    onClick: () => {
                        e.component.clearFilter();
                    }
                }
            },
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'refresh',
                    elementAttr: { title: 'Reset Grid Layout' },
                    onClick: () => {
                        e.component.state({});
                    }
                }
            }
        );
    }

    onRowDoubleClick(e) {
        if (!this.isInvoiceAdmin) return;
        this.open(e.data);
    }
    onRowPrepared(e) {
        if (e.rowType === 'data') {
            if (e.data.dumpstersOnSite == 0 && e.data.dumpster !== 'Customer Owned') {
                e.rowElement.className = e.rowElement.className + ' complete-commercial';
            }
            if (e.data.dumpstersOnSite == 0 && e.data.dumpster === 'Customer Owned') {
                e.rowElement.className = e.rowElement.className + ' commercial-customer-owned';
            }
        }
    }
    printEmailButtonClick(e) {
        if (!e) return;

        const selectedRows = this.overviewGrid.instance.getSelectedRowsData();
        if (!selectedRows) return;

        switch (e.itemData) {
            case 'Print':
                this.printInvoice(selectedRows);
                break;
            case 'Email':
                this.emailInvoice(selectedRows);
                break;
            case 'Send To':
                this.emailInvoice(selectedRows, true);
                break;
        }
    }

    open(invoice?: Invoice) {
        if (!this.isInvoiceAdmin) return;
        this.router.navigate([`/invoices/${invoice ? invoice.id : 'new'}`]);
    }

    async receivePayment() {
        const invoice = this.overviewGrid.instance.getSelectedRowsData()[0];
        if (!invoice) return;

        const fullPaymentReceived = await this.invoiceService.receivePayment(invoice);

        await this.uow.commit();
        if (!fullPaymentReceived) {
            this.router.navigate([`/invoices/payments/new/${invoice.customerId}/${invoice.id}`]);
        } else {
            this.appInfo.pleaseWaitShow();
            await this.getData();
            this.appInfo.pleaseWaitHide();
        }
    }

    async printInvoice(invoices: Invoice[]) {
        const ids = invoices.map(invoice => invoice.id);
        this.reportService.printInvoiceReport(ids);

        const printQueue = this.uow.printQueues.createEntity({
            created: new Date(),
            createdBy: this.auth.userValue.userName
        });
        invoices.forEach(invoice => {
            invoice.printed = new Date();
            if (
                invoice.statusId !== InvoiceStatus.Statuses.Paid &&
                invoice.statusId !== InvoiceStatus.Statuses.Posted &&
                invoice.statusId !== InvoiceStatus.Statuses.Complete
            )
                invoice.statusId = InvoiceStatus.Statuses.Printed;
            this.uow.printQueueInvoiceLinks.createEntity({
                printQueueId: printQueue.id,
                invoiceId: invoice.id
            });
        });
        await this.uow.commit();
    }

    async emailInvoice(invoices: Invoice[], sendToMode?: boolean) {
        const dialogRef = this.dialogService.open(EmailDialogComponent);
        dialogRef.instance.entityIds = invoices.map(invoice => invoice.id);
        dialogRef.instance.mode = 'Invoice';
        dialogRef.instance.sendToMode = sendToMode;
        const result = await dialogRef.instance.show();

        if (result.success && result.value) {
            this.appInfo.pleaseWaitShow();
            invoices.forEach(invoice => {
                if (result.value.skippedEntities.find(entity => entity.id === invoice.id)) return;

                invoice.emailed = new Date();
                if (
                    invoice.statusId !== InvoiceStatus.Statuses.Paid &&
                    invoice.statusId !== InvoiceStatus.Statuses.Posted &&
                    invoice.statusId !== InvoiceStatus.Statuses.Complete
                )
                    invoice.statusId = InvoiceStatus.Statuses.WaitingToEmail;
            });
            await this.uow.commit();
            const emailMessageIds = result.value.emailMessages.map(emailMessage => emailMessage.id);
            const success = await this.utilityService.addEmailToQueue(emailMessageIds);

            if (success) {
                let message;
                if (result.value.emailMessages) {
                    const customerEmailsSent = result.value.emailMessages.length - result.value.skippedEntities.length;
                    message = `${customerEmailsSent} email${customerEmailsSent !== 1 ? 's' : ''} sent`;
                }
                if (result.value.skippedEntities && result.value.skippedEntities.length > 0) {
                    const skippedCustomers = _(result.value.skippedEntities.map(invoice => invoice.customer.displayName))
                        .uniq()
                        .valueOf();
                    message = `${message}<br/><br/>The following customer(s) did NOT have a valid email:<br/>${skippedCustomers.join(
                        '<br/>'
                    )}`;
                }

                await alert(message, this.appInfo.appTitle);
            } else await alert(`Error sending email`, this.appInfo.appTitle);
        }
        this.dialogService.close(dialogRef);
        this.appInfo.pleaseWaitHide();
    }

    async delete(selectedInvoice: Invoice) {
        if (!(await confirm('Are you sure you want to delete the selected invoice?', this.appInfo.appTitle))) return false;

        this.appInfo.pleaseWaitShow();
        const invoice = await this.uow.invoices.byId(selectedInvoice.id, 'invoiceLines');
        invoice.deleted = new Date();
        invoice.invoiceLines.forEach(line => {
            line.jobId = null;
            if (!line.deleted) line.deleted = new Date();
        });
        await this.uow.commit();
        await this.getData();
        this.appInfo.pleaseWaitHide();
    }

    onResidentialRowDoubleClick(e) {
        if (!this.isInvoiceAdmin) return;
        this.createInvoice(e.data.rentalId);
    }
    onRentalRowDoubleClick(e) {
        if (!this.isInvoiceAdmin) return;
        this.createInvoice(e.data.id);
    }

    createInvoice(rentalId: number) {
        this.router.navigate(['/invoices/new'], { queryParams: { rentalId } });
    }

    async sendForReview(invoices: Invoice[]) {
        if (!invoices) return;
        const success = await confirm(
            `Are you sure you want to mark the selected ${invoices.length === 1 ? 'invoice' : 'invoices'} for review?`,
            this.appInfo.appTitle
        );
        if (!success) return;

        invoices.forEach(invoice => (invoice.statusId = InvoiceStatus.Statuses.Review));
        await this.uow.commit();
    }

    openRental(rentalId: number) {
        if (!this.isInvoiceAdmin) return;
        this.router.navigate([`/rentals/${rentalId}`]);
    }

    async createRevenueLines(rentalId: any) {
        // const selectedRow = this.commercialJobsGrid.instance.getSelectedRowsData()[0];
        if (!rentalId) return;

        this.appInfo.pleaseWaitShow();
        const jobs = await this.uow.jobs.getActiveJobsByRentalId(rentalId);
        const jobArray = _.orderBy(
            jobs.filter(x => {
                if (!x.invoiceLines || x.invoiceLines.length === 0) return x;
            }),
            ['startDate', 'id'],
            ['asc', 'asc']
        );
        console.log('jobs', jobArray);
        for (let index = 0; index < jobArray.length; index++) {
            const element = jobArray[index];
            await this.utilityService.generateRevenueLines(element.id);
        }
        await this.getData();
        this.appInfo.pleaseWaitHide();
    }

    async readyForExport() {
        const selectedRows = this.overviewGrid.instance.getSelectedRowsData();
        if (!selectedRows) return;

        if (selectedRows.some(invoice => invoice.quickBooksId)) {
            const success = await confirm('One or more of the selected invoices have already been exported. Do you still want to mark them as ready to export?', this.appInfo.company);
            if (!success) return;
        }

        this.appInfo.pleaseWaitShow();
        const today = new Date();
        selectedRows.forEach(invoice => {
            if (!invoice.readyToExport) invoice.readyToExport = today;
            invoice.quickBooksId = null;
        });
        await this.uow.commit();
        this.appInfo.pleaseWaitHide();
    }
}
