<div class="flex-fill d-flex flex-column dx-card">
    <div class="d-flex flex-column flex-fill responsive-paddings">
        <dx-data-grid
            #dataGrid
            class="grid-fill h-100"
            [dataSource]="photos"
            [showBorders]="true"
            [columnAutoWidth]="false"
            keyExpr="id"
            (onToolbarPreparing)="onToolbarPreparing($event)"
        >
            <dxi-column dataField="id" caption="Photo #" width="90"></dxi-column>
            <dxi-column dataField="rentalId" caption="Rental #" width="90" cellTemplate="linkTemplate"></dxi-column>
            <dxi-column dataField="name" caption="File Name" width="300" cellTemplate="fileTemplate"></dxi-column>
            <dxi-column dataField="notes" width="500"></dxi-column>
            <dxi-column dataField="created" dataType="date" width="90"></dxi-column>
            <dxi-column dataField="createdBy" width="125"></dxi-column>
            <dxi-column></dxi-column>

            <dxo-filter-row [visible]="true"></dxo-filter-row>
            <dxo-search-panel class="d-none d-sm-block" [visible]="true"></dxo-search-panel>
            <dxo-selection [mode]="'multiple'"></dxo-selection>
            <dxo-state-storing [enabled]="true" type="localStorage" storageKey="pictures-overview-grid"></dxo-state-storing>

            <div *dxTemplate="let cell of 'linkTemplate'">
                <a [routerLink]="[]" (click)="open(cell.data)">{{ cell.value || '' }}</a>
            </div>
            <div *dxTemplate="let cell of 'fileTemplate'">
                <a [routerLink]="[]" (click)="openFile(cell.data)">{{ cell.value || '' }}</a>
            </div>

            <div *dxTemplate="let data of 'toolbarButtons'">
                <button
                    class="btn btn-success"
                    (click)="rename(dataGrid.instance.getSelectedRowsData()[0])"
                    [disabled]="dataGrid.instance.getSelectedRowsData().length !== 1"
                >
                    Rename
                </button>
                <button
                    class="btn btn-success ml-2"
                    (click)="addNote(dataGrid.instance.getSelectedRowsData()[0])"
                    [disabled]="dataGrid.instance.getSelectedRowsData().length !== 1"
                >
                    Add Note
                </button>
                <button
                    class="btn btn-danger ml-2"
                    (click)="delete(dataGrid.instance.getSelectedRowsData())"
                    [disabled]="dataGrid.instance.getSelectedRowsData().length === 0"
                >
                    Delete
                </button>
            </div>
        </dx-data-grid>
    </div>
</div>
