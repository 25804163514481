// tslint:disable:no-trailing-whitespace
// tslint:disable:member-ordering
import { BaseEntity } from './base-entity';
import { Customer } from './customer';
import { Dumpster } from './dumpster';
import { Invoice } from './invoice';
import { Job } from './job';
import { ServiceAddress } from './service-address';

/// <code-import> Place custom imports between <code-import> tags
import * as moment from 'moment';
import * as _ from 'lodash';
/// </code-import>

export class Rental extends BaseEntity  {

  /// <code> Place custom code between <code> tags
  static PreferredWindowOptions = ['Morning', 'Noon', 'Afternoon', 'Evening'];

    get displayId() {
        return `R${this.id}`;
    }

    get displayName() {
        return (
            `${this.dumpster.size}, ` +
            (this.price
                ? `${this.price.toLocaleString('en-US', {
                      style: 'currency',
                      currency: 'USD'
                  })}, `
                : '') +
            `${moment(this.startDate).format('MM/DD/yyyy h:mma')} - ${moment(this.endDate).format('MM/DD/yyyy h:mma')}`
        );
    }

    get siteAddressHTML() {
        if (this.serviceAddress)
            return `${this.serviceAddress.siteName ? this.serviceAddress.siteName + ' - ' : ''}${this.serviceAddress.address1}${
                this.serviceAddress.address2 ? '</br>' + this.serviceAddress.address2 : ''
            }</br>${this.cityStateZip}`;
        else return null;
    }

    get displayAddress() {
        if (this.serviceAddress)
            return [
                this.serviceAddress.siteName ? this.serviceAddress.siteName + ' -' : null,
                this.serviceAddress.address1,
                this.serviceAddress.address2,
                this.serviceAddress.city + ',',
                this.serviceAddress.state,
                this.serviceAddress.zip
            ].join(' ');
        else return null;
    }

    get cityStateZip() {
        return `${this.serviceAddress.city ? this.serviceAddress.city : ''}${
            this.serviceAddress.city && this.serviceAddress.state ? ', ' : ''
        }${this.serviceAddress.state ? this.serviceAddress.state : ''}${this.serviceAddress.state && this.serviceAddress.zip ? '  ' : ''}${
            this.serviceAddress.zip ? this.serviceAddress.zip : ''
        }`;
    }

    get jobsRevenue() {
        let revenue = 0;
        revenue = _.sum(this.jobs.map(x => {return x.jobRevenue}));
        return revenue;
    }
  /// </code>

  // Generated code. Do not place code below this line.
  id: number;
  billDaily: boolean;
  completed: Date;
  customerId: number;
  deleted: Date;
  driverInstructions: string;
  dumpsterId: number;
  endDate: Date;
  invoiceNotes: string;
  notes: string;
  pONumber: string;
  preferredTime: Date;
  preferredWindow: string;
  price: number;
  serviceAddressId: number;
  startDate: Date;
  customer: Customer;
  dumpster: Dumpster;
  invoices: Invoice[];
  jobs: Job[];
  serviceAddress: ServiceAddress;
}

