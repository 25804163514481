import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { PendingChangesGuard, PrepareGuard } from '../core/core.module';
import { SharedModule } from '../shared/shared.module';
import { CreditDialogComponent } from './credit-dialog/credit-dialog.component';
import { CreditOverviewComponent } from './credit-overview/credit-overview.component';
import { InvoiceDetailComponent } from './invoice-detail/invoice-detail.component';
import { InvoiceLineDialogComponent } from './invoice-line-dialog/invoice-line-dialog.component';
import { InvoiceOverviewComponent } from './invoice-overview/invoice-overview.component';
import { InvoiceReviewComponent } from './invoice-review/invoice-review.component';
import { PaymentDetailComponent } from './payment-detail/payment-detail.component';
import { PaymentOverviewComponent } from './payment-overview/payment-overview.component';
import { InvoiceCommunicationsComponent } from './invoice-communications/invoice-communications.component';
import { InvoiceHistoryComponent } from './invoice-history/invoice-history.component';

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        FormsModule,
        RouterModule.forChild([
            {
                path: 'invoices',
                canActivate: [/*MsalGuard,*/ PrepareGuard],
                children: [
                    { path: '', redirectTo: 'overview', pathMatch: 'full' },
                    {
                        path: 'overview',
                        component: InvoiceOverviewComponent,
                        data: { title: 'Invoice Overview' }
                    },
                    {
                        path: 'review',
                        component: InvoiceReviewComponent,
                        data: { title: 'Invoice Review' }
                    },
                    {
                        path: 'communications',
                        component: InvoiceCommunicationsComponent,
                        data: { title: 'Communications' },
                        canDeactivate: []
                    },
                    {
                        path: 'history',
                        component: InvoiceHistoryComponent,
                        data: { title: 'Invoice History' }
                    },
                    {
                        path: ':id',
                        component: InvoiceDetailComponent,
                        data: { title: 'Invoice' },
                        canDeactivate: [PendingChangesGuard]
                    },

                    {
                        path: 'payments/:id/:customerId/:selectedInvoiceId',
                        component: PaymentDetailComponent,
                        data: { title: 'Payment' },
                        canDeactivate: [PendingChangesGuard]
                    },

                    {
                        path: 'payments',
                        component: PaymentOverviewComponent,
                        data: { title: 'Payments' },
                        canDeactivate: []
                    },
                ]
            },
            {
                path: 'payments',
                canActivate: [/*MsalGuard,*/ PrepareGuard],
                children: [
                    { path: '', redirectTo: 'overview', pathMatch: 'full' },
                    {
                        path: 'overview',
                        component: PaymentOverviewComponent,
                        data: { title: 'Payments Overview' }
                    },
                    {
                        path: ':id',
                        component: PaymentDetailComponent,
                        canDeactivate: [PendingChangesGuard]
                    },
                ]
            },
            {
                path: 'credits',
                canActivate: [/*MsalGuard,*/ PrepareGuard],
                children: [
                    { path: '', redirectTo: 'overview', pathMatch: 'full' },
                    {
                        path: 'overview',
                        component: CreditOverviewComponent,
                        data: { title: 'Credits Overview' }
                    }
                ]
            }
        ])
    ],
    declarations: [
        InvoiceDetailComponent,
        InvoiceLineDialogComponent,
        InvoiceReviewComponent,
        PaymentOverviewComponent,
        PaymentDetailComponent,
        CreditOverviewComponent,
        CreditDialogComponent,
        InvoiceCommunicationsComponent,
        InvoiceHistoryComponent
    ]
})
export class InvoiceModule {}
