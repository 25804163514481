import { Location } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DxDataGridComponent } from 'devextreme-angular';
import DataSource from 'devextreme/data/data_source';
import { default as validationEngine } from 'devextreme/ui/validation_engine';
import * as _ from 'lodash';
import * as moment from 'moment';
import {
    AppInformationService,
    AuthService,
    CdrUnitOfWork,
    ReportService,
    SessionInformationService,
    UtilityService
} from 'src/app/core/core.module';
import { Customer } from 'src/app/core/model/customer';
import { CustomerType } from 'src/app/core/model/customer-type';
import { CreditCard, CustomerRate, Dumpster, InvoiceStatus, JobDumpsterFee, QuickBooksProduct } from 'src/app/core/model/entity-model';
import { Invoice } from 'src/app/core/model/invoice';
import { InvoiceLine } from 'src/app/core/model/invoice-line';
import { Job } from 'src/app/core/model/job';
import { JobType } from 'src/app/core/model/job-type';
import { Rental } from 'src/app/core/model/rental';
import { ServiceAddress } from 'src/app/core/model/service-address';
import { InputDialogComponent } from 'src/app/shared/input-dialog/input-dialog.component';
import { DialogService, alert, confirm } from 'src/app/shared/shared.module';
import { InvoiceLineDialogComponent } from '../invoice-line-dialog/invoice-line-dialog.component';

@Component({
    selector: 'app-invoice-detail',
    templateUrl: './invoice-detail.component.html',
    styleUrls: ['./invoice-detail.component.scss']
})
export class InvoiceDetailComponent implements OnInit {
    invoice: Invoice;
    invoiceLines: InvoiceLine[] = [];
    key: number | string;
    rentalId: any;
    customers: any[];
    selectedCustomer: Customer;
    customerLocations: ServiceAddress[];
    rates: CustomerRate[];
    fuelRate: number;
    kentRate: number;
    overageRate: number;
    extendedRangeOverageRate: number;
    extensionRate: number;
    serviceRate: number;
    hasDumpsterRates: boolean = false;
    hasHaulingRates: boolean = false;
    hasZoneRates: boolean = false;
    processing: boolean = false;
    readOnly: boolean = false;
    terms = [
        {
            name: 'Net 30',
            dayOffset: 30
        },
        {
            name: 'Due on receipt',
            dayOffset: 1
        },
        {
            name: 'Net 15',
            dayOffset: 15
        }
    ];
    customersDS: DataSource;

    paymentMethods = Customer.BillingTypeOptions;
    range: string;

    products: QuickBooksProduct[];
    fuelProduct: any;
    dumpsterProduct: any;
    kentProduct: any;
    miscProduct: any;
    servicesProduct: any;
    rentalPeriod: string;
    jobDumpsterFees: any;
    jobs: any = [];
    jobInfos: any = [];
    creditCards: CreditCard[];

    @ViewChild('invoiceLineGrid') invoiceLineGrid: DxDataGridComponent;
    @ViewChild('dumpsterFeesGrid') dumpsterFeesGrid: DxDataGridComponent;

    constructor(
        private uow: CdrUnitOfWork,
        private route: ActivatedRoute,
        private router: Router,
        private location: Location,
        private appInfo: AppInformationService,
        private dialogService: DialogService,
        private authService: AuthService,
        private reportService: ReportService,
        private utility: UtilityService,
        private sessionInfo: SessionInformationService
    ) {}

    canDeactivate() {
        return !this.hasChanges();
    }
    ngOnDestroy() {
        this.sessionInfo.removeItem('bulk-approve-invoice-ids');
    }

    async ngOnInit() {
        const settings = await this.uow.settings.all();
        this.fuelRate = settings.find(setting => setting.name === 'FuelRate').decimalValue ?? 0;
        this.kentRate = settings.find(setting => setting.name === 'KentCountyRate').decimalValue ?? 0;
        this.overageRate = settings.find(setting => setting.name === 'OverageRate').decimalValue ?? 0;
        this.extendedRangeOverageRate = settings.find(setting => setting.name === 'ExtendedOverageRate').decimalValue ?? 0;
        this.extensionRate = settings.find(setting => setting.name === 'ExtensionRate').decimalValue ?? 0;
        this.serviceRate = settings.find(setting => setting.name === 'ServiceRate').decimalValue ?? 0;
        this.products = await this.uow.getQBProducts();
        this.fuelProduct = _(this.products).find(product => product.azureType === 'Fuel');
        this.dumpsterProduct = _(this.products).find(product => product.azureType === 'Dumpster');
        this.kentProduct = _(this.products).find(product => product.azureType === 'Kent County');
        this.miscProduct = _(this.products).find(product => product.azureType === 'Misc');
        this.creditCards = await this.uow.creditCards.whereActive('displayOrder, name');
        const dumpsterFees = await this.uow.dumpsterFeeTypes.whereActive();
        this.route.params.subscribe(async (params: any) => {
            this.key = params.id;
        });

        this.appInfo.pleaseWaitShow();
        if (this.key === 'new') {
            const today = new Date();
            this.invoice = this.uow.invoices.createEntity({
                statusId: InvoiceStatus.Statuses.New,
                createDate: today,
                invoiceDate: today,
                createdBy: this.authService.userValue.userName,
                dueDate: moment(today).add(30, 'days').toDate(),
                terms: 'Net 30'
            });

            this.rentalId = this.route.snapshot.queryParams.rentalId;
            if (this.rentalId) {
                const rental = await this.uow.rentals.byId(this.rentalId, 'jobs.invoiceLines, customer, serviceAddress');
                this.invoice.rental = rental;
                this.invoice.serviceAddressId = rental.serviceAddressId;
                this.invoice.customerId = rental.customerId;
                this.invoice.privateNote = rental.invoiceNotes;
                await this.setCustomer(rental.customerId);

                this.rates = await this.uow.getCustomerRates({ customerId: rental.customerId });

                this.hasDumpsterRates = !!_.find(this.rates, { rateTypeId: CustomerRate.RateTypes.Dumpster });
                this.hasHaulingRates = !!_.find(this.rates, { rateTypeId: CustomerRate.RateTypes.Hauling });
                this.hasZoneRates = !!_.find(this.rates, { rateTypeId: CustomerRate.RateTypes.Zone });

                // use the customer overrides if they exist
                if (this.invoice.rental.customer.fuelRate) this.fuelRate = this.invoice.rental.customer.fuelRate;
                if (this.invoice.rental.customer.extensionRate) this.extensionRate = this.invoice.rental.customer.extensionRate;

                await this.createInvoiceLines(rental, rental.jobs);
                this.setServiceAddress();
                this.setBillToAddress();
                await this.getDumpsterFees();
            } else {
                await this.getCustomers();
            }
        } else {
            await this.getData();
            await this.getDumpsterFees();
        }

        this.setPermission();
        this.getRentalPeriod();
        await this.calculateTotals();
        this.appInfo.pleaseWaitHide();
    }

    async getData() {
        this.invoice = await this.uow.getInvoice(this.key);
        this.invoiceLines = await this.uow.getActiveInvoiceLines(this.key);
        this.invoiceLines.forEach(x => {
            if (!!x.job && !_.includes(this.jobs, x.job)) this.jobs.push(x.job);
        });
        const paymentLines = await this.uow.getPaymentLines(this.key);
        this.invoice.payments = _(paymentLines).sumBy('amount');
        this.setPermission();
    }

    async getDumpsterFees() {
        let dumpsterFees = [];
        const jobs = this.jobs;
        for (let index = 0; index < jobs.length; index++) {
            const job = jobs[index];
            const jobDumpsterFees = await this.uow.jobs.getJobDumpsterFees(job.id);
            jobDumpsterFees.forEach(fee => {
                dumpsterFees.push(fee);
            });
        }
        this.jobDumpsterFees = dumpsterFees;
    }

    setPermission() {
        this.readOnly = this.invoice.statusId === InvoiceStatus.Statuses.Posted;
    }

    getRentalPeriod() {
        if (this.invoice && this.invoice.rental && this.invoice.rental.jobs) {
            const minJob = _.orderBy(this.invoice.rental.jobs, 'completed')[0];
            const maxJob = _.orderBy(this.invoice.rental.jobs, 'completed', 'desc')[0];
            const maxString = `J${maxJob.id} ${moment(maxJob.completed).format('MM/DD/YYYY')}`;
            this.rentalPeriod = `J${minJob.id} ${moment(minJob.completed).format('MM/DD/YYYY')}${
                maxJob && maxJob.completed ? ' - ' + maxString : ''
            }`;
        }
    }

    async getCustomers() {
        this.customers = (await this.uow.customers.getActiveCustomers(null, 'id, typeId, companyName, firstName, lastName')) as any[];
        this.customers.forEach(customer => {
            customer.displayName =
                customer.typeId === CustomerType.Types.Commercial
                    ? customer.companyName
                    : [customer.firstName, customer.lastName].join(' ');
        });

        this.customersDS = new DataSource({
            store: this.customers as any[],
            paginate: true
        });
    }

    async createInvoiceLines(rental: Rental, jobs: Job[]) {
        await this.getCustomerLocations(rental.customerId);
        const lineCount = await this.createJobInvoiceLines(rental, jobs);
        if (lineCount > 0) this.createFuelInvoiceLines(rental, lineCount);
    }

    async createJobInvoiceLines(rental: Rental, jobs: Job[]): Promise<number> {
        let jobCount = 0;

        let array = _.orderBy(
            jobs.filter(x => {
                if (!x.invoiceLines || x.invoiceLines.length === 0) return x;
            }),
            ['startDate', 'id'],
            ['asc', 'asc']
        );

        const billByDay = rental.billDaily;
        if (rental.billDaily) {
            this.jobInfos = [];

            array = await this.uow.getRentalJobsToBillDaily(rental.id);
            this.jobInfos = await this.uow.getBillableDaysByJobIds(rental.id);

            array = await this.uow.jobs.byIds(
                this.jobInfos.map(x => {
                    return x.id;
                })
            );
        }
        console.log('array', array);

        for (let index = 0; index < array.length; index++) {
            const job = array[index];
            const residential = job.rental.customer.typeId === CustomerType.Types.Residential;
            const commercial = job.rental.customer.typeId === CustomerType.Types.Commercial;
            const pickup = job.actionId === JobType.Actions.PickUp;
            const delivery = job.actionId === JobType.Actions.Delivery;
            const serviceJob = job.jobTypeId === JobType.Types.Service;

            //determine weight overage jobs
            const residentialWeightOverage =
                residential &&
                pickup &&
                job.weight &&
                job.weight !== 0 &&
                job.billAsDumpster.weightLimit !== 0 &&
                job.weight > job.billAsDumpster.weightLimit;

            //
            let commercialBillDeliveryWeightOverage = false;
            let deliveryOfOverweightCommercial;
            if (commercial && pickup && job.rental.customer.billingRule === 'Bill After Delivery') {
                //determine what type of commercial job it is (regular or an overWeight one)
                //creates a 2nd invoice for the weight overage
                commercialBillDeliveryWeightOverage = true;
            }

            const checkForResidentialOverExtended =
                residential && pickup && job.rental.customer.standardRentalLength && job.rental.customer.standardRentalLength !== 0;
            if (checkForResidentialOverExtended) {
                //check if there was an over-extended rental for residential customers, the jobs are skipped below in CreateInvoiceForJob
                const extension = await this.getDumpsterExtension(job.id);
                if (extension.rentalDays > job.rental.customer.standardRentalLength) {
                    extension.extendedDays = extension.rentalDays - job.rental.customer.standardRentalLength;
                    extension.service += ` (${job.rental.customer.standardRentalLength} included)`;
                    this.createExtensionLine(extension, job);
                }
            }

            const createInvoiceForJob =
                serviceJob ||
                // (commercial && pickup && job.rental.customer.billingRule !== 'Bill After Delivery') ||
                (commercial && pickup) ||
                (commercial && delivery && job.rental.customer.billingRule === 'Bill After Delivery') ||
                (residential && pickup && job.rental.customer.billingRule === 'Bill After Pickup') ||
                (residential && delivery && job.rental.customer.billingRule !== 'Bill After Pickup') ||
                (residential && residentialWeightOverage) ||
                billByDay;

            if (createInvoiceForJob && job.completed) {
                this.jobs.push(job);

                const dumpster = job.billAsDumpster ? job.billAsDumpster : job.dumpster;
                const lineRate = this.getLineRate(dumpster.id, job.rental.serviceAddress.range, job.rental.serviceAddress.zone);
                const concrete = dumpster.size === 'Concrete';
                let billDailyPlaceOnlyJob = false;

                if (!lineRate && !residential) {
                    const result = await confirm(
                        `Could not find a rate that matched - Range: ${job.rental.serviceAddress.range} or Zone: ${job.rental.serviceAddress.zone}.  Continue?`,
                        `${this.appInfo.company}`
                    );
                    if (!result) return;
                }

                let haulingRate = null;
                if (this.hasHaulingRates && !!lineRate) {
                    haulingRate = lineRate.rate + this.getAdditionalHaulingMileageRate(lineRate, job.rental.serviceAddress.distance);
                }
                const jobRate = this.hasHaulingRates
                    ? haulingRate
                    : commercialBillDeliveryWeightOverage
                    ? 0
                    : !!lineRate
                    ? lineRate.rate
                    : rental.price;

                let lineDescription = `J${job.id} ${moment(job.startDate).format('M/D/YYYY')}${
                    this.hasHaulingRates
                        ? ' Hauling Rate'
                        : serviceJob && job.notes
                        ? ' ' + job.notes
                        : residentialWeightOverage || commercialBillDeliveryWeightOverage
                        ? `  (${job.billAsDumpster.weightLimit} tons included)`
                        : ''
                }`;

                if (!residentialWeightOverage && !commercialBillDeliveryWeightOverage) {
                    let billQuantity = 0;
                    let jobInfo: any;
                    if (billByDay) {
                        // console.log(job.id);
                        jobInfo = _.find(this.jobInfos, { id: job.id });
                        // console.log(jobInfo);
                        billQuantity = jobInfo.totalDays - (jobInfo.lastBilledDate ? 0 : jobInfo.invoicedDays);
                        jobInfo.billQuantity = billQuantity;
                        const start = jobInfo.lastBilledDate ? moment(jobInfo.lastBilledDate).add(1, 'days').toDate() : jobInfo.start;
                        billDailyPlaceOnlyJob = jobInfo.type === 'placed';

                        lineDescription = `J${job.id} Billing Daily ${moment(start).format('M/D/YYYY')} - ${moment(jobInfo.end).format(
                            'M/D/YYYY'
                        )}${billDailyPlaceOnlyJob ? ' Placement ONLY' : ''}`;
                    }

                    const invoiceLine = this.uow.invoiceLines.createEntity({
                        createDate: new Date(),
                        createdBy: this.authService.userValue.userName,
                        invoiceId: this.invoice.id,
                        lineNumber: Invoice.getNextLineNumber(this.invoiceLines),
                        quantity: billByDay ? billQuantity : 1,
                        rate: serviceJob ? this.serviceRate : residentialWeightOverage ? 0 : billByDay ? 35 : jobRate,
                        rentalId: rental.id,
                        service: serviceJob ? 'Service Charge' : `${dumpster.size} Container${billByDay ? ' Billed Daily' : ''}`,
                        description: lineDescription,
                        weight: serviceJob ? null : job.weight,
                        jobId: job.id,
                        quickBooksProductId: this.dumpsterProduct ? this.dumpsterProduct.id : null,
                        note:
                            residential && dumpster.id !== job.dumpsterId
                                ? `${job.dumpster.size} job was used on a ${dumpster.size} rental`
                                : null
                    });

                    this.invoiceLines.push(invoiceLine);
                    if (!serviceJob && !billDailyPlaceOnlyJob) jobCount++;
                }

                //check for Price/Ton customer rate
                if (!!lineRate && !!lineRate.pricePerTon && !serviceJob && !concrete && !billDailyPlaceOnlyJob) {
                    this.createPricePerTonLines(rental, job, lineRate);
                }
                //check for Kent County
                if (
                    job.rental.serviceAddress.county === 'Kent' &&
                    !job.rental.customer.bypassKentCharge &&
                    !residential &&
                    !serviceJob &&
                    !concrete &&
                    !billDailyPlaceOnlyJob
                ) {
                    this.createKentInvoiceLine(rental, job, job.weight);
                }
                //check if there was an over-extended rental  //this check is for Commercial customers, residential is handled elsewhere
                if (
                    job.rental.customer.standardRentalLength &&
                    job.rental.customer.standardRentalLength !== 0 &&
                    !serviceJob &&
                    !billDailyPlaceOnlyJob
                ) {
                    const extension = await this.getDumpsterExtension(job.id);
                    if (extension.rentalDays > job.rental.customer.standardRentalLength) {
                        extension.extendedDays = extension.rentalDays - job.rental.customer.standardRentalLength;
                        extension.service += ` (${job.rental.customer.standardRentalLength} included)`;
                        this.createExtensionLine(extension, job);
                    }
                }
                //check for Weight Overage lines
                const commercialOverage =
                    !!lineRate && !!lineRate.weightLimit && job.weight > lineRate.weightLimit && !serviceJob && !billDailyPlaceOnlyJob;
                if (commercialOverage || residentialWeightOverage) {
                    const weightOverage = commercialOverage
                        ? job.weight - lineRate.weightLimit
                        : job.weight - job.billAsDumpster.weightLimit;
                    const rate = rental.serviceAddress.range === 'Regular' ? this.overageRate : this.extendedRangeOverageRate;
                    if (weightOverage > 0)
                        await this.createWeightOverageLines(
                            rental,
                            job,
                            weightOverage,
                            rate,
                            residentialWeightOverage,
                            commercialBillDeliveryWeightOverage,
                            dumpster,
                            lineRate
                        );
                }
            }
        }

        return jobCount;
    }

    async findMatchingJob(job: Job): Promise<Job> {
        let matchingJob;
        const location = await this.uow.getDumpsterLocationByJobId(job.id);
        const deliveryJob = await this.uow.jobs.byId(location.placedJobId);
        return deliveryJob;
    }

    async getDumpsterExtension(jobId: number): Promise<DumpsterExtension> {
        //job action is pickup => use the removed dumpster locations
        const extension = new DumpsterExtension();
        const dumpsterLocation = await this.uow.getDumpsterLocationByJobId(jobId);
        if (!!dumpsterLocation) {
            const startJob = `J${dumpsterLocation.placedJobId} ${moment(dumpsterLocation.placed).format('MM/DD/YYYY')}`;
            const endJob = `J${dumpsterLocation.removedJobId} ${moment(dumpsterLocation.removed).format('MM/DD/YYYY')}`;
            const rentalDays = moment(dumpsterLocation.removed).diff(dumpsterLocation.placed, 'days');
            extension.rentalDays = rentalDays;
            extension.service = `${startJob} - ${endJob}: ${rentalDays} days`;
            extension.rate = this.extensionRate;
        }
        return extension;
    }

    getLineRate(dumpsterTypeId: number, range: string, zone: number): CustomerRate {
        if (this.hasZoneRates) {
            const dumpsterRate = _.find(this.rates, {
                rateTypeId: CustomerRate.RateTypes.Zone,
                zone: zone
            });
            return dumpsterRate;
        }
        if (this.hasHaulingRates) {
            const haulingRate = _.find(this.rates, { rateTypeId: CustomerRate.RateTypes.Hauling, range: range });
            return haulingRate;
        }

        let dumpsterRate;
        let noDumpsterMatch = false;
        if (this.hasDumpsterRates) {
            //check first for a dumpster rate in Customer Rates, then use Dumpster base/extended rates
            dumpsterRate = _.find(this.rates, {
                rateTypeId: CustomerRate.RateTypes.Dumpster,
                dumpsterId: dumpsterTypeId,
                range: range
            });
            if (!dumpsterRate) {
                noDumpsterMatch = true;
            }
            return dumpsterRate;
        }

        if (noDumpsterMatch || (!this.hasDumpsterRates && !this.hasHaulingRates && !this.hasZoneRates)) {
            dumpsterRate = this.getDumpsterRate(dumpsterTypeId, range);
            return dumpsterRate;
        }
    }

    getDumpsterRate(dumpsterTypeId: number, range: string): CustomerRate {
        const dumpster = _.find(this.uow.lookups.dumpsters, {
            id: dumpsterTypeId
        });
        const tempRate = new CustomerRate();
        tempRate.dumpsterId = dumpsterTypeId;
        tempRate.rate = range === 'Regular' ? dumpster.basePrice : dumpster.extendedPrice;
        return tempRate;
    }

    getAdditionalHaulingMileageRate(rate: CustomerRate, distance: number): number {
        //distance minus base and then every integer increment over is an additional charge
        let distanceToBill = 0;
        if (!!rate.baseMileage && !!rate.mileageOffset && !!rate.mileageOffsetRate && !!distance && distance > rate.baseMileage)
            distanceToBill = Math.floor((distance - rate.baseMileage) / rate.mileageOffset) * rate.mileageOffsetRate;
        return distanceToBill;
    }

    createServiceLines(rental: Rental) {
        const array = rental.jobs.filter(x => {
            if ((!x.invoiceLines || x.invoiceLines.length === 0) && x.actionId === JobType.Types.Service) {
                return x;
            }
        });
        for (let index = 0; index < array.length; index++) {
            const job = array[index];
            if (job.completed) {
                const invoiceLine = this.uow.invoiceLines.createEntity({
                    createDate: moment(new Date()),
                    createdBy: this.authService.userValue.userName,
                    invoiceId: this.invoice.id,
                    lineNumber: Invoice.getNextLineNumber(this.invoiceLines),
                    quantity: 1,
                    rate: this.serviceRate,
                    rentalId: job.rentalId,
                    service: 'Service Charge',
                    description: job.notes,
                    weight: null,
                    quickBooksProductId: this.dumpsterProduct ? this.dumpsterProduct.id : null,
                    jobId: job.id
                });
                this.invoiceLines.push(invoiceLine);
            }
        }
    }

    createExtensionLine(extension: DumpsterExtension, job: Job) {
        const invoiceLine = this.uow.invoiceLines.createEntity({
            createDate: moment(new Date()),
            createdBy: this.authService.userValue.userName,
            invoiceId: this.invoice.id,
            lineNumber: Invoice.getNextLineNumber(this.invoiceLines),
            quantity: extension.extendedDays,
            rate: extension.rate,
            rentalId: job.rentalId,
            service: 'Extended Rental',
            description: extension.service,
            weight: null,
            quickBooksProductId: this.dumpsterProduct ? this.dumpsterProduct.id : null,
            jobId: job.id
        });
        this.invoiceLines.push(invoiceLine);
    }

    createPricePerTonLines(rental: Rental, job: Job, rate: CustomerRate) {
        const invoiceLine = this.uow.invoiceLines.createEntity({
            createDate: moment(new Date()),
            createdBy: this.authService.userValue.userName,
            invoiceId: this.invoice.id,
            lineNumber: Invoice.getNextLineNumber(this.invoiceLines),
            quantity: !job.weight || job.weight <= 0 ? 1 : job.weight <= 1 ? Math.ceil(job.weight) : job.weight,
            rate: rate.pricePerTon,
            rentalId: rental.id,
            service: `Price Per Ton`,
            description: null,
            weight: null,
            quickBooksProductId: this.dumpsterProduct ? this.dumpsterProduct.id : null,
            jobId: job.id
        });
        this.invoiceLines.push(invoiceLine);
    }

    async createWeightOverageLines(
        rental: Rental,
        job: Job,
        weightOverage: number,
        rate: number,
        residentialWeightOverage: boolean,
        commercialBillDeliveryWeightOverage: boolean,
        dumpster: Dumpster,
        customerRate: CustomerRate
    ) {
        let lineDescription;
        if (residentialWeightOverage) {
            lineDescription =
                `J${job.id} ${moment(job.startDate).format('M/D/YYYY')}` +
                ` (${dumpster.size} Container -- ${dumpster.weightLimit} tons included)`;
        }

        if (commercialBillDeliveryWeightOverage) {
            const deliveryJob = await this.findMatchingJob(job);
            const weightLimit = customerRate ? customerRate.weightLimit : deliveryJob.billAsDumpster.weightLimit
            lineDescription =
                `Delivery J${deliveryJob.id} ${moment(deliveryJob.startDate).format('M/D/YYYY')}` +
                ` -- Pickup J${job.id} ${moment(job.startDate).format('M/D/YYYY')}` +
                ` (${deliveryJob.billAsDumpster.size} Container -- ${weightLimit} tons included)`;
        }

        const invoiceLine = this.uow.invoiceLines.createEntity({
            createDate: moment(new Date()),
            createdBy: this.authService.userValue.userName,
            invoiceId: this.invoice.id,
            lineNumber: Invoice.getNextLineNumber(this.invoiceLines),
            quantity: weightOverage.toFixed(2),
            rate: rate,
            rentalId: rental.id,
            service: `Weight Overage`,
            description: residentialWeightOverage || commercialBillDeliveryWeightOverage ? lineDescription : null,
            weight: residentialWeightOverage || commercialBillDeliveryWeightOverage ? job.weight : null,
            jobId: job.id,
            quickBooksProductId: this.dumpsterProduct ? this.dumpsterProduct.id : null
        });
        this.invoiceLines.push(invoiceLine);
    }

    createFuelInvoiceLines(rental: Rental, lineCount: number) {
        const invoiceLine = this.uow.invoiceLines.createEntity({
            createDate: moment(new Date()),
            createdBy: this.authService.userValue.userName,
            invoiceId: this.invoice.id,
            lineNumber: Invoice.getNextLineNumber(this.invoiceLines),
            quantity: lineCount,
            rate: this.fuelRate,
            rentalId: rental.id,
            service: `Fuel surcharge`,
            description: ``,
            weight: null,
            quickBooksProductId: this.fuelProduct ? this.fuelProduct.id : null
        });
        this.invoiceLines.push(invoiceLine);
    }

    createKentInvoiceLine(rental: Rental, job: Job, weight: number) {
        const invoiceLine = this.uow.invoiceLines.createEntity({
            createDate: moment(new Date()),
            createdBy: this.authService.userValue.userName,
            invoiceId: this.invoice.id,
            lineNumber: Invoice.getNextLineNumber(this.invoiceLines),
            quantity: !weight || weight <= 0 ? 1 : weight <= 1 ? Math.ceil(weight) : weight,
            rate: this.kentRate,
            rentalId: rental.id,
            service: `Kent County surcharge`,
            description: ``,
            weight: null,
            quickBooksProductId: this.kentProduct ? this.kentProduct.id : null,
            jobId: !!job ? job.id : null
        });
        this.invoiceLines.push(invoiceLine);
    }

    async addKentFee() {
        await this.save();
        this.appInfo.pleaseWaitShow();
        this.createKentInvoiceLine(this.invoice.rental, null, null);
        await this.calculateTotals();
        await this.save();
        this.invoiceLineGrid.instance.clearSelection();
        this.appInfo.pleaseWaitHide();
    }
    async addFuelCharge() {
        await this.save();
        this.appInfo.pleaseWaitShow();
        this.createFuelInvoiceLines(this.invoice.rental, 1);
        await this.calculateTotals();
        await this.save();
        this.invoiceLineGrid.instance.clearSelection();
        this.appInfo.pleaseWaitHide();
    }

    onToolbarPreparing(e) {
        e.toolbarOptions.items.unshift(
            {
                location: 'before',
                template: 'toolbarButtons'
            },
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'revert',
                    elementAttr: { title: 'Reset Filters' },
                    onClick: () => {
                        e.component.clearFilter();
                    }
                }
            },
            {
                location: 'after',
                widget: 'dxButton',
                options: {
                    icon: 'refresh',
                    elementAttr: { title: 'Reset Grid Layout' },
                    onClick: () => {
                        e.component.state({});
                    }
                }
            }
        );
    }

    async onCustomerChanged(e) {
        await this.setCustomer(e.value);
    }
    async setCustomer(customerId?: number) {
        if (!customerId) {
            this.customerLocations = [];
            this.invoice.serviceAddressId = null;
            this.clearServiceAddress();
            this.clearBillToAddress();
            this.clearCustomerDefaults();
        } else {
            this.selectedCustomer = await this.uow.customers.byId(customerId);
            await this.getCustomerLocations(customerId);
            this.setBillToAddress();
            this.setCustomerDefaults();
        }
    }

    paymentMethodChanged = e => {
        const paymentMethod = e.value as string;
        if (paymentMethod === 'Credit-Debit') this.setCreditCard(this.invoice.customer?.creditCardId);
        else this.setCreditCard();
    };

    creditCardChanged = e => {
        const creditCardId = e.value as number;
        this.setCreditCard(creditCardId);
    };

    setCreditCard(creditCardId?: number) {
        if (!creditCardId) {
            if (this.invoice.creditCardId != null) this.invoice.creditCardId = null;
            this.invoice.creditCardPercentage = null;
        } else {
            if (this.invoice.creditCardId !== creditCardId) this.invoice.creditCardId = creditCardId;
            const creditCard = this.creditCards.find(c => c.id === creditCardId);
            this.invoice.creditCardPercentage = creditCard.feePercentage;
        }
        this.calculateTotals();
    }

    async getCustomerLocations(customerId: number) {
        const locations = await this.uow.getActiveServiceAddresses(customerId);
        this.customerLocations = locations;
    }
    setBillToAddress() {
        this.invoice.billToPhone = this.selectedCustomer.phone;
        this.invoice.billToEmail = this.selectedCustomer.email;
        if (this.selectedCustomer.firstName || this.selectedCustomer.lastName)
            this.invoice.billToContactName = [this.selectedCustomer.firstName, this.selectedCustomer.lastName].join(' ');

        if (this.selectedCustomer.billingSameAsService) {
            this.invoice.billToAddress1 = this.invoice.serviceAddress1;
            this.invoice.billToAddress2 = this.invoice.serviceAddress2;
            this.invoice.billToCity = this.invoice.serviceCity;
            this.invoice.billToState = this.invoice.serviceState;
            this.invoice.billToZip = this.invoice.serviceZip;
            this.invoice.billToCountry = this.invoice.serviceCountry;
        } else {
            this.invoice.billToAddress1 = this.selectedCustomer.billAddress1;
            this.invoice.billToAddress2 = this.selectedCustomer.billAddress2;
            this.invoice.billToCity = this.selectedCustomer.billCity;
            this.invoice.billToState = this.selectedCustomer.billState;
            this.invoice.billToZip = this.selectedCustomer.billZip;
            this.invoice.billToCountry = this.selectedCustomer.billCountry;
        }
    }

    clearBillToAddress() {
        this.invoice.billToPhone = null;
        this.invoice.billToEmail = null;
        this.invoice.billToContactName = null;
        this.invoice.billToAddress1 = null;
        this.invoice.billToAddress2 = null;
        this.invoice.billToCity = null;
        this.invoice.billToState = null;
        this.invoice.billToZip = null;
        this.invoice.billToCountry = null;
    }

    onTermsChanged = e => {
        let dayOffset = 1;
        if (e.selectedItem) dayOffset = e.selectedItem.dayOffset;
        const dueDate = moment(this.invoice.invoiceDate).add(dayOffset, 'days').toDate();
        this.invoice.dueDate = dueDate;
    };

    setCustomerDefaults() {
        this.invoice.paymentMethod = this.selectedCustomer.billingType;
        const term = this.terms.find(term => term.name.toLowerCase() === this.selectedCustomer.paymentTerms?.toLowerCase());
        this.invoice.terms = term ? term.name : 'Net 30';
    }

    clearCustomerDefaults() {
        this.invoice.paymentMethod = null;
        this.invoice.terms = null;
    }

    onSiteLocationChanged(e) {
        if (!e.value) this.clearServiceAddress();
        else this.setServiceAddress(e.value);
    }

    setServiceAddress(serviceAddressId?: number) {
        let location = null;
        if (serviceAddressId) location = this.customerLocations.find(location => location.id === serviceAddressId);
        else location = this.invoice.rental.serviceAddress;
        this.invoice.serviceAddress1 = location.address1;
        this.invoice.serviceAddress2 = location.address2;
        this.invoice.serviceCity = location.city;
        this.invoice.serviceState = location.state;
        this.invoice.serviceZip = location.zip;
        this.invoice.serviceCountry = location.country;
        if (this.selectedCustomer && this.selectedCustomer.billingSameAsService) this.setBillToAddress();
    }

    clearServiceAddress() {
        this.invoice.serviceAddress1 = null;
        this.invoice.serviceAddress2 = null;
        this.invoice.serviceCity = null;
        this.invoice.serviceState = null;
        this.invoice.serviceZip = null;
        this.invoice.serviceCountry = null;
    }

    rowDoubleClick(e) {
        if (this.readOnly) return;

        this.addInvoiceLine(e.data);
    }

    async save() {
        if (!this.valid()) {
            await alert('Please fix errors before saving', this.appInfo.appTitle);
            return false;
        }

        if (!this.invoice.invoiceNumber) this.invoice.invoiceNumber = (await this.utility.getNextNumber('InvoiceNumber')) as any;

        if (this.key === 'new') {
            await this.uow.commit();
            this.key = this.invoice.id;
            this.router.navigate([`../${this.invoice.id}`], { relativeTo: this.route, replaceUrl: true });
        }

        await this.uow.commit();
        await this.utility.updateCustomerBalance(this.invoice.customerId);

        //call endpoint to set the billedDays and lastBilledDate of the rental jobs
        if (this.invoice.rental?.billDaily) {
            const placedJobIds = this.jobInfos.filter(x => {
                if (x.type === 'placed') return x.id;
            });

            for (let index = 0; index < this.jobInfos.length; index++) {
                const jobInfo = this.jobInfos[index];
                const dumpsterLocation = await this.uow.dumpsterLocations.byId(jobInfo.locationId);
                dumpsterLocation.billedDays += jobInfo.billQuantity;
                dumpsterLocation.lastBilledDate = jobInfo.end;
            }
            await this.uow.commit();
        }

        return true;
    }

    async cancel() {
        if (!(await confirm('Are you sure you want to cancel changes?', this.appInfo.appTitle))) return false;
        this.uow.rollback();
        if (!this.key || this.key === 'new') this.close();
    }

    close() {
        this.utility.updateCustomerBalance(this.invoice.customerId);
        try {
            this.location.back();
        } catch {
            this.router.navigate(['../'], { relativeTo: this.route });
        }
    }

    hasChanges() {
        return this.uow.hasChanges();
    }

    valid() {
        const groupConfig = validationEngine.getGroupConfig('invoiceValidationGroup');
        if (groupConfig) {
            const result = validationEngine.validateGroup('invoiceValidationGroup');
            return result.isValid;
        } else return false;
    }

    async addInvoiceLine(invoiceLine?: InvoiceLine, miscItem: boolean = false) {
        if (!(await this.save())) return;

        if (!invoiceLine) {
            invoiceLine = this.uow.invoiceLines.createEntity({
                createDate: moment(new Date()),
                createdBy: this.authService.userValue.userName,
                invoiceId: this.invoice.id,
                lineNumber: Invoice.getNextLineNumber(this.invoiceLines),
                miscItem: miscItem,
                quantity: 1,
                quickBooksProductId: this.miscProduct ? this.miscProduct.id : null
            });
        }

        let dialogRef = await this.dialogService.open(InvoiceLineDialogComponent);
        dialogRef.instance.invoiceLine = invoiceLine;
        const results = await dialogRef.instance.show();

        if (results.success) {
            await this.save();
            await this.getData();
            this.calculateTotals();
            await this.save();
        } else this.uow.rollback();
        this.dialogService.close(dialogRef);
    }

    async removeInvoiceLine(invoiceLines?: InvoiceLine[]) {
        const result = await confirm(`Are you sure you want to remove the selected invoice line?`, this.appInfo.appTitle);
        if (!result) return;

        const save = await this.save();
        if (!save) return;

        for (let index = 0; index < invoiceLines.length; index++) {
            const line = invoiceLines[index];
            line.jobId = null;
            this.invoiceLines.splice(this.invoiceLines.indexOf(line), 1);
            line.deleted = new Date();
        }

        this.updateDisplayOrder(this.invoiceLines);
        await this.save();
        await this.getData();
        this.calculateTotals();
        await this.save();
    }

    async addDumpsterFeeLine(jobDumpsterFee: JobDumpsterFee) {
        if (!jobDumpsterFee) return;

        const save = await this.save();
        if (!save) return;

        const jobLines = this.invoiceLines.filter(line => line.jobId === jobDumpsterFee.jobId);
        const lowestJobLine = _(jobLines).orderBy('lineNumber', 'desc').first();

        if (!lowestJobLine) {
            const success = await confirm(
                `Could not find invoice line for job #${jobDumpsterFee.jobId}. Do you still want to add the dumpster fee?`,
                this.appInfo.company
            );
            if (!success) return;
        }

        const lineNumber = lowestJobLine ? lowestJobLine.lineNumber + 1 : Invoice.getNextLineNumber(this.invoiceLines);
        const dumpsterFeeLine = this.uow.invoiceLines.createEntity({
            invoiceId: this.invoice.id,
            rentalId: this.invoice.rentalId,
            jobId: jobDumpsterFee.jobId,
            quickBooksProductId: QuickBooksProduct.Products.Miscellaneous,
            service: jobDumpsterFee.dumpsterFeeType.name,
            quantity: jobDumpsterFee.quantity,
            rate: jobDumpsterFee.dumpsterFeeType.price,
            total: jobDumpsterFee.quantity * jobDumpsterFee.dumpsterFeeType.price,
            note: jobDumpsterFee.note,
            lineNumber: lineNumber,
            createdBy: this.authService.userValue.userName,
            createDate: new Date()
        });

        this.invoiceLines.filter(line => line.lineNumber >= lineNumber).forEach(line => (line.lineNumber += 1));
        this.invoiceLines.splice(lineNumber - 1, 0, dumpsterFeeLine);

        this.calculateTotals();
        await this.save();
    }

    calculateTotals() {
        if (this.readOnly) return;

        let total = 0;
        this.invoiceLines.forEach(line => {
            total += line.total;
        });
        // disabled including credit card fee in totals. Uncomment to add back
        // if (this.invoice.paymentMethod === 'Credit-Debit' && this.invoice.creditCardPercentage)
        //     total += total * this.invoice.creditCardPercentage;
        if (this.invoice.total.toFixed(2) !== total.toFixed(2)) this.invoice.total = total;

        if (this.invoice.unpaidBalance !== this.invoice.balance) this.invoice.balance = this.invoice.unpaidBalance;
    }

    async move(invoiceLine: InvoiceLine, direction: 'up' | 'down') {
        if (this.processing) return;
        this.processing = true;
        const increment = direction === 'down' ? 1 : -1;
        const otherInvoiceLine = this.invoiceLines.find(
            otherInvoiceLine => otherInvoiceLine.lineNumber === invoiceLine.lineNumber + increment
        );
        if (otherInvoiceLine) {
            invoiceLine.lineNumber += increment;
            otherInvoiceLine.lineNumber -= increment;
            this.sort(this.invoiceLines);
            await this.save();
        }
        this.processing = false;
    }

    updateDisplayOrder(invoiceLines: InvoiceLine[]) {
        _.orderBy(this.invoiceLines, 'lineNumber').forEach((invoiceLine, index) => {
            if (invoiceLine.lineNumber !== index + 1) invoiceLine.lineNumber = index + 1;
        });
    }

    sort(invoiceLines: InvoiceLine[]) {
        invoiceLines.sort((a, b) => (a.lineNumber || 9999) - (b.lineNumber || 9999));
    }

    printInvoice() {
        this.reportService.printInvoiceReport([this.invoice.id]);
    }

    async sendForReview(invoice: Invoice) {
        if (!invoice) return;

        const success = await confirm(`Are you sure you want to mark the selected invoice for review?`, this.appInfo.appTitle);
        if (!success) return;

        invoice.statusId = InvoiceStatus.Statuses.Review;
        await this.save();
    }

    async changeStatusAndNext(reject: boolean = false) {
        this.appInfo.pleaseWaitShow();
        this.invoice.statusId = reject ? InvoiceStatus.Statuses.Rejected : InvoiceStatus.Statuses.Queue;

        const reviewInvoiceIds = this.sessionInfo.getItem('bulk-approve-invoice-ids') as number[];
        const success = await this.save();
        if (!success || !reviewInvoiceIds) {
            this.appInfo.pleaseWaitHide();
            return;
        }

        const currentIndex = reviewInvoiceIds.indexOf(this.invoice.id);
        const nextId = reviewInvoiceIds[currentIndex + 1];
        if (!nextId) {
            this.appInfo.pleaseWaitHide();
            return;
        }

        this.router.navigate([`/invoices/${nextId}`]);
    }

    async editInvoice() {
        const success = await confirm(
            `Are you sure you want to UNLOCK this invoice? Doing so will set the status to In Process and any changes will need to be re-posted to QuickBooks.`,
            this.appInfo.appTitle
        );
        if (!success) return;

        this.appInfo.pleaseWaitShow();
        this.invoice.statusId = InvoiceStatus.Statuses.InProcess;
        this.invoice.quickBooksLastExport = null;
        await this.save();

        await this.getData();
        this.appInfo.pleaseWaitHide();
    }

    async deleteInvoice() {
        const result = await confirm('Are you sure you want to delete the selected invoice?', this.appInfo.appTitle);
        if (!result) return false;

        this.appInfo.pleaseWaitShow();
        // const invoice = await this.uow.invoices.byId(selectedInvoice.id, 'invoiceLines');
        this.invoice.deleted = new Date();
        this.invoice.invoiceLines.forEach(line => {
            line.jobId = null;
            line.deleted = new Date();
        });
        await this.save();
        await this.getData();
        await this.router.navigate([`/invoices/overview`]);
        this.appInfo.pleaseWaitHide();
    }

    async voidInvoice() {
        const payments = await this.uow.getPaymentLines(this.invoice.id);
        if (payments.length > 0) {
            await alert(`Invoice has associated Payments.  Cannot Void.`, this.appInfo.appTitle);
            return;
        }

        let message = 'Are you sure you want to VOID this invoice';
        if (!!this.invoice.quickBooksId) {
            message = `This invoice has already been sent to QuickBooks. ${message}`;
        }

        const success = await confirm(`${message}`, this.appInfo.appTitle);
        if (!success) return;

        const dialogRef = this.dialogService.open(InputDialogComponent);
        dialogRef.instance.dialogHeader = 'Void Invoice';
        dialogRef.instance.dialogPrompt = `Void Reason`;
        dialogRef.instance.dialogWidth = 400;
        dialogRef.instance.mode = 'textarea';
        const result = await dialogRef.instance.show();

        if (result.success && result.value) {
            this.appInfo.pleaseWaitShow();
            this.invoice.privateNote = result.value;
            this.invoice.quickBooksId = 'Voided';
            this.invoice.quickBooksLastExport = new Date();
            this.invoice.deleted = new Date();
            this.invoice.statusId = InvoiceStatus.Statuses.Voided;
            await this.save();
        }
        this.dialogService.close(dialogRef);
        await this.getData();
        this.appInfo.pleaseWaitHide();
    }

    async editDumpsterFeeNote(jobDumpsterFee: JobDumpsterFee) {
        const success = await this.save();
        if (!success) return;

        const dialogRef = this.dialogService.open(InputDialogComponent);
        dialogRef.instance.dialogHeader = 'Edit Note';
        dialogRef.instance.dialogWidth = 400;
        dialogRef.instance.mode = 'textarea';
        dialogRef.instance.dialogValue = jobDumpsterFee.note;
        const result = await dialogRef.instance.show();

        if (result.success && result.value) {
            this.appInfo.pleaseWaitShow();
            jobDumpsterFee.note = result.value;
            await this.save();
            this.appInfo.pleaseWaitHide();
        }
    }
}

class DumpsterExtension {
    rentalDays: number;
    extendedDays: number;
    service: string;
    rate: number;
}
