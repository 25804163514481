<div class="d-flex flex-column flex-fill dx-card">
    <div class="d-flex flex-row align-items-end mx-4 mt-3">
        <div>
            <label>Statuses</label>
            <dx-tag-box
                [(value)]="filter.statusIds"
                [items]="invoiceStatuses"
                valueExpr="id"
                displayExpr="name"
                showSelectionControls="true"
                showDropDownButton="true"
                style="max-width: 400px; min-width: 150px"
            ></dx-tag-box>
        </div>
        <div class="ml-4">
            <label>Include Deleted</label>
            <dx-check-box [(value)]="filter.includeDeleted" class="ml-2"></dx-check-box>
        </div>
        <button type="button" accesskey="s" (click)="getData()" class="btn btn-primary ml-4">Search</button>
    </div>
    <div class="d-flex flex-row flex-fill p-2">
        <div class="d-flex flex-column flex-fill p-1">
            <dx-data-grid
                #dataGrid
                keyExpr="id"
                class="flex-fill d-flex flex-column"
                height="650px"
                [dataSource]="invoices"
                [showBorders]="true"
                (onToolbarPreparing)="onToolbarPreparing($event)"
                (onSelectionChanged)="selectionChange($event)"
                [columnAutoWidth]="false"
            >
                <dxi-column dataField="id" caption="Id" width="75" cellTemplate="invoiceLinkTemplate"></dxi-column>
                <dxi-column dataField="createDate" caption="Created" width="125"></dxi-column>
                <dxi-column dataField="invoiceNumber" caption="Invoice #" width="75" cellTemplate="invoiceLinkTemplate"></dxi-column>
                <dxi-column dataField="rentalIdDisplay" caption="Rental" width="75" cellTemplate="rentalLinkTemplate"></dxi-column>
                <dxi-column dataField="status.name" caption="Status" width="100"> </dxi-column>
                <dxi-column
                    dataField="customer.displayName"
                    caption="Customer"
                    width="175"
                    cellTemplate="customerLinkTemplate"
                ></dxi-column>
                <dxi-column dataField="displayServiceAddress" caption="Service Address"></dxi-column>
                <dxi-column dataField="paid" width="100"></dxi-column>
                <dxi-column dataField="invoiceDate" width="100"></dxi-column>
                <dxi-column dataField="dueDate" width="100"></dxi-column>
                <dxi-column dataField="total" [format]="{ type: 'currency', precision: 2 }" width="200"></dxi-column>
                <dxi-column dataField="unpaidBalance" [format]="{ type: 'currency', precision: 2 }" width="200"></dxi-column>
                <dxi-column dataField="deleted" width="100"></dxi-column>
                <dxi-column dataField="isPaid" width="100"></dxi-column>
                <dxi-column dataField="isDeleted" width="100"></dxi-column>
                <dxi-column dataField="quickBooksId" caption="QB Id" width="100"></dxi-column>
                <dxi-column dataField="readyToExport" width="100"></dxi-column>
                <dxi-column dataField="quickBooksLastExport" caption="QB Last Export" width="100"></dxi-column>
                <dxi-column></dxi-column>

                <div *dxTemplate="let cell of 'invoiceLinkTemplate'">
                    <a *ngIf="cell" [routerLink]="'/invoices/' + cell.data.id" [innerHtml]="cell.text"></a>
                </div>
                <div *dxTemplate="let cell of 'rentalLinkTemplate'">
                    <p *ngIf="cell && !cell.data.rentalId" [innerHtml]="cell.text"></p>
                    <a *ngIf="cell && cell.data.rentalId" [routerLink]="'/rentals/' + cell.data.rentalId" [innerHtml]="cell.text"></a>
                </div>
                <div *dxTemplate="let cell of 'customerLinkTemplate'">
                    <a *ngIf="cell" [routerLink]="'/customers/' + cell.data.customerId" [innerHtml]="cell.text"></a>
                </div>

                <div *dxTemplate="let data of 'toolbarButtons'">
                    <button
                        class="btn btn-sm btn-success mr-1 mb-2"
                        (click)="readyForExport()"
                        [disabled]="dataGrid.instance.getSelectedRowsData().length === 0"
                        [hidden]="!isInvoiceAdmin"
                    >
                        Ready To Export
                    </button>
                </div>

                <div *dxTemplate="let data of 'toolbarText'">
                    <span *ngIf="dataGrid" class="mr-3">
                        <strong>{{ dataGrid.instance.totalCount() + ' items' }}</strong>
                    </span>
                </div>

                <dxo-export [enabled]="true" [allowExportSelectedData]="true"></dxo-export>
                <dxo-filter-row [visible]="true"></dxo-filter-row>
                <dxo-search-panel [visible]="true"></dxo-search-panel>
                <dxo-selection [mode]="'multiple'"></dxo-selection>
                <dxo-state-storing [enabled]="true" type="localStorage" storageKey="invoice-history-grid"></dxo-state-storing>

                <dxo-summary>
                    <dxi-total-item
                        column="total"
                        summaryType="sum"
                        [displayFormat]="'{0}'"
                        [valueFormat]="{ type: 'currency', precision: 2 }"
                    >
                    </dxi-total-item>
                    <dxi-total-item
                        column="unpaidBalance"
                        summaryType="sum"
                        [displayFormat]="'{0}'"
                        [valueFormat]="{ type: 'currency', precision: 2 }"
                    >
                    </dxi-total-item>
                </dxo-summary>
            </dx-data-grid>
        </div>
    </div>
</div>
