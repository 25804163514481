import { Component, NgModule, Input, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UserPanelModule } from '../user-panel/user-panel.component';
import { DxButtonModule } from 'devextreme-angular/ui/button';
import { DxToolbarModule } from 'devextreme-angular/ui/toolbar';
import { DxMenuModule } from 'devextreme-angular/ui/menu';
import { AppInformationService, AuthService } from 'src/app/core/core.module';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-header',
    templateUrl: 'header.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
    user$ = this.auth.user$;
    userSubscription: Subscription;

    @Output()
    menuToggle = new EventEmitter<boolean>();

    @Input()
    menuToggleEnabled = false;

    @Input()
    appTitle: string;

    @Input()
    title: string;

    mainMenuItems = [
        { text: 'Notes', url: '/dispatch-notes' },
        { text: 'Customers', url: '/customers', roles: ['Customer', 'Customer:Admin'] },
        { text: 'Rentals', url: '/rentals', roles: ['Rentals', 'Rentals:Admin'] },
        // { text: 'Jobs', url: '/jobs' },
        { text: 'Schedule', url: '/schedule', roles: ['Schedule', 'Schedule:Admin'] },
        { text: 'Dumpsters', url: '/dumpsters', roles: ['Dumpster', 'Dumpster:Admin'] },
        {
            text: 'Inventory',
            url: '/inventory',
            items: [
                { text: 'Overview', url: '/inventory/overview' },
                { text: 'Substitutions', url: '/inventory/substitutions' }
            ]
        },
        {
            text: 'Invoices',
            url: '/invoices',
            roles: ['Invoice', 'Invoice:Admin'],
            items: [
                { text: 'Overview', url: '/invoices/overview' },
                { text: 'Waiting to be Reviewed', url: '/invoices/review' },
                { text: 'Communications', url: '/invoices/communications' },
                { text: 'History', url: '/invoices/history' }
            ]
        },
        {
            text: 'Reporting',
            url: '/reporting',
            roles: ['Reporting'],
            items: [
                { text: 'AR Aging', url: '/reporting/araging' },
                { text: 'Revenue Forecast', url: '/reporting/revenue-forecast' },
                { text: 'Revenue by Type', url: '/reporting/revenue-by-type' }
            ]
        },
        { text: 'Payments', url: '/payments', roles: ['Payments', 'Payments:Admin'] },
        // { text: 'Credits', url: '/credits' },
        { text: 'Great Plains', url: '/great-plains', roles: ['GreatPlains'] },
        {
            text: 'Admin',
            url: '/admin',
            roles: ['Admin'],
            items: [
                { text: 'Users', url: '/admin/users/overview' },
                { text: 'Destination', url: '/admin/storage-dump-sites/overview' },
                { text: 'Lookup Tables', url: '/admin/lookup-tables' },
                { text: 'Customer Rates', url: '/admin/customer-rates' },
                { text: 'Photo Overview', url: '/admin/photo-overview' }
            ]
        }
    ];

    userMenuItems = [
        {
            text: 'Profile',
            icon: 'user'
        },
        {
            text: 'Logout',
            icon: 'runner',
            onClick: () => {
                this.auth.logout();
            }
        }
    ];

    constructor(private auth: AuthService, private router: Router, route: ActivatedRoute, public appInfo: AppInformationService) {}

    async ngOnInit() {
        this.userSubscription = this.auth.user$.subscribe(user => {
            if (user) this.mainMenuItems = this.mainMenuItems.filter(i => !i.roles || this.auth.hasAnyRole(i.roles));
        });
    }

    goHome() {
        this.router.navigateByUrl('/');
    }

    toggleMenu = () => {
        this.menuToggle.emit();
    };

    isActiveItem(url: string) {
        return this.router.url.startsWith(url);
    }
}

@NgModule({
    imports: [CommonModule, DxButtonModule, RouterModule, UserPanelModule, DxToolbarModule, DxMenuModule],
    declarations: [HeaderComponent],
    exports: [HeaderComponent]
})
export class HeaderModule {}
