// tslint:disable:no-trailing-whitespace
// tslint:disable:member-ordering
import { BaseEntity } from './base-entity';
import { Invoice } from './invoice';
import { Job } from './job';
import { QuickBooksProduct } from './quick-books-product';
import { Rental } from './rental';

/// <code-import> Place custom imports between <code-import> tags

/// </code-import>

export class InvoiceLine extends BaseEntity  {

  /// <code> Place custom code between <code> tags
  get total() {
    return this.quantity * this.rate;
  }

  get tax() {
    return 0;
  }

  get totalWithTax() {
    return this.total + this.tax;
  }
  /// </code>

  // Generated code. Do not place code below this line.
  id: number;
  createDate: Date;
  createdBy: string;
  deleted: Date;
  description: string;
  invoiceId: number;
  jobId: number;
  lineNumber: number;
  miscItem: boolean;
  note: string;
  quantity: number;
  quickBooksProductId: number;
  rate: number;
  rentalId: number;
  service: string;
  weight: string;
  invoice: Invoice;
  job: Job;
  quickBooksProduct: QuickBooksProduct;
  rental: Rental;
}

