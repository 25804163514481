import { MetadataStore } from 'breeze-client';

import { AuditLog } from './audit-log';
import { Contact } from './contact';
import { CreditCard } from './credit-card';
import { Customer } from './customer';
import { CustomerRate } from './customer-rate';
import { CustomerType } from './customer-type';
import { DispatchNote } from './dispatch-note';
import { Dumpster } from './dumpster';
import { DumpsterCategory } from './dumpster-category';
import { DumpsterFeeType } from './dumpster-fee-type';
import { DumpsterFeeTypeDumpsterLink } from './dumpster-fee-type-dumpster-link';
import { DumpsterInventory } from './dumpster-inventory';
import { DumpsterLocation } from './dumpster-location';
import { EmailMessage } from './email-message';
import { EventLog } from './event-log';
import { FuelRatePercentage } from './fuel-rate-percentage';
import { Invoice } from './invoice';
import { InvoiceLine } from './invoice-line';
import { InvoiceStatus } from './invoice-status';
import { Job } from './job';
import { JobDumpsterFee } from './job-dumpster-fee';
import { JobType } from './job-type';
import { Maintenance } from './maintenance';
import { Note } from './note';
import { Payment } from './payment';
import { PaymentCredit } from './payment-credit';
import { PaymentLine } from './payment-line';
import { PaymentMethod } from './payment-method';
import { Photo } from './photo';
import { PrintQueue } from './print-queue';
import { PrintQueueInvoiceLink } from './print-queue-invoice-link';
import { QuickBooksProduct } from './quick-books-product';
import { QuickBooksTask } from './quick-books-task';
import { QuickBooksTaskResponse } from './quick-books-task-response';
import { RateType } from './rate-type';
import { Rental } from './rental';
import { RevenueLine } from './revenue-line';
import { RevenueType } from './revenue-type';
import { Role } from './role';
import { SalesPerson } from './sales-person';
import { ServiceAddress } from './service-address';
import { Setting } from './setting';
import { Statement } from './statement';
import { StorageDumpSite } from './storage-dump-site';
import { User } from './user';
import { UserRole } from './user-role';

export class CdrRegistrationHelper {

    static register(metadataStore: MetadataStore) {
        metadataStore.registerEntityTypeCtor('AuditLog', AuditLog);
        metadataStore.registerEntityTypeCtor('Contact', Contact);
        metadataStore.registerEntityTypeCtor('CreditCard', CreditCard);
        metadataStore.registerEntityTypeCtor('Customer', Customer);
        metadataStore.registerEntityTypeCtor('CustomerRate', CustomerRate);
        metadataStore.registerEntityTypeCtor('CustomerType', CustomerType);
        metadataStore.registerEntityTypeCtor('DispatchNote', DispatchNote);
        metadataStore.registerEntityTypeCtor('Dumpster', Dumpster);
        metadataStore.registerEntityTypeCtor('DumpsterCategory', DumpsterCategory);
        metadataStore.registerEntityTypeCtor('DumpsterFeeType', DumpsterFeeType);
        metadataStore.registerEntityTypeCtor('DumpsterFeeTypeDumpsterLink', DumpsterFeeTypeDumpsterLink);
        metadataStore.registerEntityTypeCtor('DumpsterInventory', DumpsterInventory);
        metadataStore.registerEntityTypeCtor('DumpsterLocation', DumpsterLocation);
        metadataStore.registerEntityTypeCtor('EmailMessage', EmailMessage);
        metadataStore.registerEntityTypeCtor('EventLog', EventLog);
        metadataStore.registerEntityTypeCtor('FuelRatePercentage', FuelRatePercentage);
        metadataStore.registerEntityTypeCtor('Invoice', Invoice);
        metadataStore.registerEntityTypeCtor('InvoiceLine', InvoiceLine);
        metadataStore.registerEntityTypeCtor('InvoiceStatus', InvoiceStatus);
        metadataStore.registerEntityTypeCtor('Job', Job);
        metadataStore.registerEntityTypeCtor('JobDumpsterFee', JobDumpsterFee);
        metadataStore.registerEntityTypeCtor('JobType', JobType);
        metadataStore.registerEntityTypeCtor('Maintenance', Maintenance);
        metadataStore.registerEntityTypeCtor('Note', Note);
        metadataStore.registerEntityTypeCtor('Payment', Payment);
        metadataStore.registerEntityTypeCtor('PaymentCredit', PaymentCredit);
        metadataStore.registerEntityTypeCtor('PaymentLine', PaymentLine);
        metadataStore.registerEntityTypeCtor('PaymentMethod', PaymentMethod);
        metadataStore.registerEntityTypeCtor('Photo', Photo);
        metadataStore.registerEntityTypeCtor('PrintQueue', PrintQueue);
        metadataStore.registerEntityTypeCtor('PrintQueueInvoiceLink', PrintQueueInvoiceLink);
        metadataStore.registerEntityTypeCtor('QuickBooksProduct', QuickBooksProduct);
        metadataStore.registerEntityTypeCtor('QuickBooksTask', QuickBooksTask);
        metadataStore.registerEntityTypeCtor('QuickBooksTaskResponse', QuickBooksTaskResponse);
        metadataStore.registerEntityTypeCtor('RateType', RateType);
        metadataStore.registerEntityTypeCtor('Rental', Rental);
        metadataStore.registerEntityTypeCtor('RevenueLine', RevenueLine);
        metadataStore.registerEntityTypeCtor('RevenueType', RevenueType);
        metadataStore.registerEntityTypeCtor('Role', Role);
        metadataStore.registerEntityTypeCtor('SalesPerson', SalesPerson);
        metadataStore.registerEntityTypeCtor('ServiceAddress', ServiceAddress);
        metadataStore.registerEntityTypeCtor('Setting', Setting);
        metadataStore.registerEntityTypeCtor('Statement', Statement);
        metadataStore.registerEntityTypeCtor('StorageDumpSite', StorageDumpSite);
        metadataStore.registerEntityTypeCtor('User', User);
        metadataStore.registerEntityTypeCtor('UserRole', UserRole);
    }
}
